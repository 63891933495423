import ACTIVITY from './activity';
import * as address from './address';
import * as common from './common';
import * as companyId from './companyId';
import * as companyInfo from './companyInfo';
import * as contactInfo from './contactInfo';
import * as contactUs from './contactUs';
import * as contractConfirmation from './contractConfirmation';
import * as location from './location';
import * as paymentInfo from './paymentInfo';
import * as pos from './pos';

const schemas = {
  // ADDRESS
  STREET_NAME: address.streetName,
  REGION: common.STRING,
  HOUSE_NUMBER: address.houseNumber,
  POSTAL_CODE: address.postalCode,
  CITY: common.STRING,

  // COMPANY INFO
  COMPANY_NAME: common.STRING,
  COURT_FILE_NUMBER: common.STRING,
  COMPANY_ID: companyId.default,
  VAT_NUMBER: companyInfo.vatNumber,
  VAT_PAYER: common.BOOL,
  COMPANY_ACTIVITY: companyInfo.activity,

  // CONTACT INFO
  FIRST_NAME: common.STRING,
  LAST_NAME: common.STRING,
  PHONE_NUMBER: contactInfo.phoneNumber,
  EMAIL: contactInfo.email,
  POSITION: common.STRING_NULLABLE,

  // PAYMENT INFO
  BANK: common.STRING,
  BANK_NUMBER: paymentInfo.bankNumber,
  ACCOUNT_NUMBER: paymentInfo.accountNumber,
  IBAN: paymentInfo.accountNumberIBAN,
  // CUSTOMER_CARD,

  // CONTRACT CONFIRMATION
  CONTRACT_TERMS: contractConfirmation.terms,
  POLICY: contractConfirmation.policy,
  POA: contractConfirmation.poaFilename,

  // // EMPLOYEE DETAILS
  // IS_CONTACT_PERSON: employee.isContactPerson,
  // RESPONSIBILITY: employee.responsibility,
  // CONTACT_DEPARTMENT: employee.contactDepartment,
  // NGM_PORTAL_ACCESS: employee.hasNgmPortalAccess,
  // NGM_ROLE: employee.ngmRole,
  // ASSIGNED_LOCATIONS: employee.locations,
  // NGM_PORTAL_ACCESS_EMAIL: employee.ngmPortalAccessEmail,

  // LOCATION DETAILS
  LOCATION_NAME: common.STRING,
  WEBSITE_URL: location.WEB,
  WIFI: common.BOOL,

  // POS DETAILS
  POS_DEVICE_TYPE: common.STRING,
  POS_BANK: pos.posBank,
  POS_EMAIL: pos.posEmail,
  POS_PRODUCTS: pos.posAcceptedProducts,
  POS_TERMINAL_ID: pos.posTerminalId,

  // PRODUCT CATEGORIES
  ACTIVITY,
  ACCEPTED_PRODUCTS: location.acceptedProducts,

  // // PAYMENTS
  // PAYMENT_ACCOUNT: payments.accountNumber,
  // PAYMENT_AMOUNT: payments.amount,
  // PAYMENT_NOTE: payments.note,
  // PAYMENT_LOCATION: payments.locationId,
  // PAYMENT_CODE: payments.code,
  
  // CONTACT US FORM
  CONTACT_US_FIRST_NAME: contactUs.firstName,
  CONTACT_US_LAST_NAME: contactUs.lastName,
  CONTACT_US_EMAIL: contactUs.email,
  CONTACT_US_TOPIC: contactUs.topic,
  CONTACT_US_SUBTOPIC: contactUs.subtopic,
  CONTACT_US_PHONE: contactUs.phone,
  CONTACT_US_MESSAGE: contactUs.message,
  CONTACT_US_CAPTCHA: contactUs.captcha,

  // COMMON
  STRING: common.STRING,
  BOOL: common.BOOL,

  // BELGIUM SPECIFIC VALIDATIONS
  BE_ACCEPTED_PRODUCTS: location.beAcceptedProducts,
  BE_MARKETPLACES: pos.posMarketplaces,
  BE_COMPANY_ID: companyId.beCompanyId,
  BE_POSTAL_CODE: address.bePostalCode,

  BE_LOCATION_PAYMENT_OPTIONS: location.bePaymentOptions,
  // Physical terminal
  BE_TERMINAL: common.STRING,
  BE_TERMINAL_ID: pos.bePosTerminalId,
  BE_TERMINAL_ID_UNIQUE: pos.bePosTerminalIdUnique,
  // Online terminal
  BE_TERMINAL_ONLINE: location.beOnlineTerminalProvider,
  BE_TERMINAL_ID_ONLINE: location.beOnlineTerminalId,
  BE_LOCATION_CITY: location.bePhysicalCity,
  BE_LOCATION_HOUSE_NUMBER: location.bePhysicalHouseNumber,
  BE_LOCATION_STREET_NAME: location.bePhysicalStreetName,
  BE_LOCATION_POSTAL_CODE: location.bePhysicalPostalCode,
  BE_LOCATION_ACTIVITY: common.STRING,
  BE_LOCATION_EMAIL: location.beWebshopEmail,
  BE_LOCATION_WEBSITE_URL: location.beWeb,

  BOX: common.STRING,
  LEGAL_FORM: common.STRING,
  LANGUAGE: common.STRING_NULLABLE,
  PO_REF_NUMBER: common.STRING,
};

export default schemas;
